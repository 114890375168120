import React from "react";
import ppt from "./ppt1.pdf";

const Knowhow = () => {
  return (
    <div>
      <iframe
        src={ppt}
        height={700}
        width={"100%"}
        frameBorder={0}
        title="knowHow"
        style={{
          display: "block",
          width: "100%",
          border: "none",
        }}
      ></iframe>
    </div>
  );
};

export default Knowhow;
