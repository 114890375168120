import React from "react";
import { useState } from "react";
import LoaderTri from "../../components/Loader/LoaderTri";
import { Image } from "antd";
import sushilContactImage from "../../assets/images/w.jpg";

const ContactUs = () => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-7 text-center ">
          <br />
          {isLoading && <LoaderTri />}
          {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d488835.6773490089!2d74.14183921456835!3d16.824930800000022!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc123b5dab20501%3A0x27b3eda7b5387101!2sVardhaman%20Distributors!5e0!3m2!1sen!2sin!4v1690288630003!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d488835.6773490089!2d74.14183921456835!3d16.824930800000022!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc123b5dab20501%3A0x27b3eda7b5387101!2sVardhaman%20Distributors!5e0!3m2!1sen!2sin!4v1690288630003!5m2!1sen!2sin"
            width="90%"
            height="450"
            style={{
              border: "2px solid white",
              borderRadius: 10,
            }}
            allowFullScreen={false}
            title="VARDHAMAN DISTRIBUTORS -  Location"
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            onLoad={() => {
              console.log("onLoad");
              setIsLoading(false);
            }}
          ></iframe>

          <br />
          <br />
          <br />
        </div>
        <div className="col-md-5">
          <br />
          {/* <br />
                    <br /> */}

          <div className=" " style={{ width: "90%" }}>
            <h4>VARDHAMAN DISTRIBUTORS</h4>

            <h6>
              Shivaji Nagar, Maa Hospital,behind Shivshankar Theatre,Miraj,
            </h6>
            <h6>Maharashtra 41641</h6>
            <h6>Contact:-</h6>
            <h6> +91 233 2222019</h6>
            <h6> +91 233 2229406</h6>
            <h6> +91 233 2222119 </h6>

            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
