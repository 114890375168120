import React from "react";
import img2 from "./logo.png";
const HeaderImage = () => {
  return (
    <img
      src={img2}
      height={60}
      width={30}
      id="S"
      alt=""
      style={{
        marginTop: "5px",
        width: "100%",
        height: window.innerWidth < 768 && "auto",
      }}
    />
  );
};

export default HeaderImage;
