import { useState } from "react";
import { useEffect } from "react";
import { Table } from "react-bootstrap";
import CustomerFooter from "./CustomerFooter";
import HeaderCustomer from "./HeaderCustomer";
import './Customer.css';
import * as XLSX from 'xlsx';
import ApiHandler from "../../ApiHandler";
import TransferData from "../../TransferData";
import LoaderTri from "../Loader/LoaderTri";

export default function PendingBills() {


    const [showPendingBillData, setShowPendingBillData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);



    useEffect(() => {

        setIsLoading(true)
        const localUrl = JSON.parse(localStorage.getItem('LocalURL'))
        const user = JSON.parse(localStorage.getItem('user-info'))
        ApiHandler.post({
            url: localUrl + "api/OwnApp/GetPendingBill",
            data: {
                // startdate: "20210101",
                // enddate: "20230712",
                code: user.code,

            }, useUserUrl: true
        })
            .then(((result) => {
                console.log(result);
                console.log(result.data);
                setShowPendingBillData(result.data)

            }))
            .catch(err => {
                console.error(err);
                if (err.response && err.response.status && err.response.status === 401) {
                    console.log(err.response);
                    //   RefreshTokenHandler();
                    //  ToastError("Token has expired Please refresh");
                }
            })
            .finally(() => {
                setIsLoading(false)
            })





        // axios.post(localUrl + "api/OwnApp/GetPendingBill", {
        //     startdate: "20210101",
        //     enddate: "20230712",
        //     code: 15201,
        // },

        // )
        //     .then(((result) => {
        //         console.log(result);
        //         console.log(result.data);
        //         setShowPendingBillData(result.data)


        //     }))
        //     .catch(err => {
        //         console.error(err);
        //         if (err.response && err.response.status && err.response.status === 401) {
        //             console.log(err.response);
        //             //   RefreshTokenHandler();
        //             //  ToastError("Token has expired Please refresh");
        //         }
        //     }

        //     )
    }, [])

    function handleExportDataExcel(_Color) {
        console.log(showPendingBillData);
        var wb = XLSX.utils.book_new(),
            ws = XLSX.utils.json_to_sheet(showPendingBillData);
        //let a = wb.filter(c => c.split === _Color)
        XLSX.utils.book_append_sheet(wb, ws, "MySheet1");
        XLSX.writeFile(wb, "Reports.xlsx");
    }





    return (
        <>
            <HeaderCustomer />

            <div style={{ minHeight: window.innerHeight / 1.3 }}>

                <div className="d-flex justify-content-center"  >
                    <div className="pendingBillData"  >

                        <div className="row m-0">
                            <div className="col-lg-6 offset-lg-3   ">
                                <div className="pendingBill row">
                                    <div className="col-6 col-lg-6 ">
                                        <h4>Pending Bills</h4>
                                    </div>
                                    {showPendingBillData.length > 0 && <div div className="col-6 col-lg-6 text-end">
                                        <button onClick={handleExportDataExcel}>Download</button>
                                    </div>}

                                </div>



                                <div style={{
                                    width: "100%",
                                    overflowX: "scroll"
                                }}  >
                                    <Table striped  >
                                        <thead className="threAd" >
                                            <tr className="threAd_tr">

                                                <th>{TransferData.billDate}</th>
                                                <th>{TransferData.billNo}</th>
                                                <th>{TransferData.amount}</th>
                                                <th>{TransferData.pending}</th>
                                                <th>{TransferData.runningTotal}</th>
                                                <th>{TransferData.days}</th>
                                                <th>{TransferData.duedate}</th>


                                            </tr>
                                        </thead>

                                        {




                                            showPendingBillData.map((elm, index) => {
                                                return <tbody className="tboDY" key={index} >
                                                    <tr  >
                                                        <td style={{ color: elm._Color === "Y" ? '#ff9999' : ' ' }}>{elm.billDate}</td>
                                                        <td style={{ color: elm._Color === "Y" ? '#ff9999' : ' ' }}>{elm.billNo}</td>
                                                        <td style={{ color: elm._Color === "Y" ? '#ff9999' : ' ', textAlign: "right" }}>{elm.amount}</td>
                                                        <td style={{ color: elm._Color === "Y" ? '#ff9999' : ' ', textAlign: "right" }}>{elm.pending}</td>
                                                        <td style={{ color: elm._Color === "Y" ? '#ff9999' : ' ' }}>{elm.runningTotal}</td>
                                                        <td style={{ color: elm._Color === "Y" ? '#ff9999' : ' ' }}>{elm.days}</td>
                                                        <td style={{ color: elm._Color === "Y" ? '#ff9999' : ' ' }}>{elm.duedate}</td>


                                                    </tr>


                                                </tbody>
                                            })
                                        }
                                    </Table >
                                </div>

                            </div>
                        </div>

                        {isLoading &&
                            <div className="d-flex justify-content-center" >

                                <LoaderTri />

                            </div>

                        }
                    </div>
                </div>



            </div >
            <CustomerFooter />
        </>
    )
}