import React from "react";
import qrcode from "../../../Images/qrcode.jpg";

import Slider from "./Slider";
import MarqueeLComList from "./MarqueeLComList";
import { Image } from "antd";
import vardmanPDF from "./vard.pdf";
import "./homepagecomp.css";

import pdfImage from "./vardImg.png";

const HomeComponent = () => {
  return (
    <>
      <div className="row m-0">
        <div className="col-md-6">
          <div className="row">
            <div
              className="col-lg-12     "
              style={
                {
                  // height: window.innerHeight / 1.5,
                }
              }
            >
              <div className="img text-end mt-5" style={{}}>
                <Slider />
              </div>
              <br />
              <div style={{ height: "200px", width: "200px" }}>
                <Image
                  style={{
                    borderRadius: 14,
                  }}
                  src={qrcode}
                  width={"80%"}
                  height={"auto"}
                />
              </div>
              <br />
            </div>
          </div>
        </div>
        <div className="col-md-6 ">
          <div className="row h-100 ">
            <div className="api col-lg-6">
              <MarqueeLComList />
            </div>
            <div className=" mt-2 mb-2 col-lg-6 text-center">
              <div
                style={
                  {
                    // width: "100%",
                    // backgroundColor: "white",
                  }
                }
                id="pdfShowingId"
              >
                <br />
                <img
                  src={pdfImage}
                  alt="pdf"
                  style={{
                    borderRadius: 34,
                    cursor: "pointer",
                    width: "75%",
                    height: "auto",
                  }}
                  onClick={(e) => {
                    const zoomLevel = "view=fitV";
                    window.open(vardmanPDF + `#${zoomLevel}`);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeComponent;
