import React from "react";
import "./about.css";
const AboutUs = () => {
  console.log("About Us");
  return (
    <div className="  row m-0" style={{ backgroundColor: "#ef415b" }}>
      <div className="col-lg-10 offset-lg-1" style={{ color: "white" }}>
        <p
          className="MsoNormal"
          align="center"
          style={{
            fontSize: "large",
            marginBottom: "0in",
            textAlign: "center",
          }}
        >
          <b>
            <br />
            <u>Vardhaman Distributors Miraj </u>
          </b>
        </p>
        <p
          className="MsoNormal"
          align="center"
          style={{ marginBottom: "0in", textAlign: "center" }}
        >
          <b>
            <u>
              <br />
              Oncology, Life Saving Injectable &amp; Pharmacy Distribution
              Portfolio at A Glance.
            </u>
          </b>
        </p>
        <br />
        <p className="MsoNormal" style={{ marginBottom: "0in" }}>
          <u>We Coverage Major of 6 Districts in Western Maharashtra </u>
        </p>
        <p
          className="MsoListParagraphCxSpFirst"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "0in",
            marginLeft: "1.0in",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Wingdings" }}>
            <span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp; </span>
          </span>
          <b>
            <li>Kolhapur</li>
          </b>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "0in",
            marginLeft: "1.0in",
            textIndent: "-.25in",
          }}
        >
          <b>
            <li>Satara</li>
          </b>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "0in",
            marginLeft: "1.0in",
            textIndent: "-.25in",
          }}
        >
          <b>
            <li>Sangli</li>
          </b>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "0in",
            marginLeft: "1.0in",
            textIndent: "-.25in",
          }}
        >
          <b>
            <li>Ratnagiri</li>
          </b>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "0in",
            marginLeft: "1.0in",
            textIndent: "-.25in",
          }}
        >
          <b>
            <li>Solapur</li>
          </b>
        </p>
        <p
          className="MsoListParagraphCxSpLast"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "0in",
            marginLeft: "1.0in",
            textIndent: "-.25in",
          }}
        >
          <b>
            <li>Belgaum, Karnataka.</li>
          </b>
          <br />
        </p>
        <p className="MsoNormal" style={{ marginBottom: "0in" }}>
          <b>
            <u>Our Major Client list:</u>
            <br />
          </b>
        </p>
        <p
          className="MsoListParagraphCxSpFirst"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "0in",
            marginLeft: "1.0in",
            textIndent: "-.25in",
          }}
        >
          <li>Tata Cancer Hospital, Barshi</li>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "0in",
            marginLeft: "1.0in",
            textIndent: "-.25in",
          }}
        >
          <li>Kolhapur Cancer Centre</li>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "0in",
            marginLeft: "1.0in",
            textIndent: "-.25in",
          }}
        >
          <li>ONCO-Life Cancer Care</li>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "0in",
            marginLeft: "1.0in",
            textIndent: "-.25in",
          }}
        >
          <li>Krishna Institute, Karad</li>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "0in",
            marginLeft: "1.0in",
            textIndent: "-.25in",
          }}
        >
          <li>Horizon Multispeciality Hospital, Sangli</li>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "0in",
            marginLeft: "1.0in",
            textIndent: "-.25in",
          }}
        >
          <li> MGM Hospital, Miraj</li>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "0in",
            marginLeft: "1.0in",
            textIndent: "-.25in",
          }}
        >
          <li>Aster Aadhar Hospital</li>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "0in",
            marginLeft: "1.0in",
            textIndent: "-.25in",
          }}
        >
          <li>Apple Saraswati</li>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "0in",
            marginLeft: "1.0in",
            textIndent: "-.25in",
          }}
        >
          <li>Manipal Hospital</li>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "0in",
            marginLeft: "1.0in",
            textIndent: "-.25in",
          }}
        >
          <li>Sahyadri Hospital</li>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "0in",
            marginLeft: "1.0in",
            textIndent: "-.25in",
          }}
        >
          <li>Sidhivinyak Cancer Hospital</li>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "0in",
            marginLeft: "1.0in",
            textIndent: "-.25in",
          }}
        >
          <li>Bharati Hospital</li>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "0in",
            marginLeft: "1.0in",
            textIndent: "-.25in",
          }}
        >
          And Such 50 institution tie up!
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{ marginBottom: "0in" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginBottom: "0in",

            fontSize: "large  ",
          }}
        >
          Geographically reachable to all hospitals within 200kms, within 24
          hours, Centrally located, also border of Karnataka for expansion.
        </p>
        <br />
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{ marginBottom: "0in" }}
        >
          Staff of 75 people in which major FOCUS is anticancer drugs. Blood
          emergency medicines, walk-in cold room 3000 cc and cold chain delivery
          systems with self-owned vehicles and contract couriers.
        </p>
        <br />
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{ marginBottom: "0in" }}
        >
          Direct access to major doctors and hospitals. Patient drug counseling
          and transparency in software sales analysis and customer support.
        </p>
        <br />

        <p
          className="MsoListParagraphCxSpMiddle"
          style={{ marginBottom: "0in" }}
        >
          Working in the pharma care industry for 50 years. We have extensive
          experience in controlling the drug distribution industry against PO,
          large inventory, doctor relationship goodwill, quotation and ethical
          purchasing practices.
        </p>
        <br />
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{ marginBottom: "0in" }}
        >
          <p textAlign="centre">
            All-round services available 24/7. For B2B. Major investment with
            FD/OD self-reliance banking support. Anytime ATM for extra stocks to
            hold for particular deals of the future. Control of dead stocks to
            report frequently. Auto Email of stock statement. Party-wise sales.
            E-Invoicing, Salesmen Billing App, Customer order, every bill CSV
            file, and auto SMS of invoices to customers.{" "}
          </p>
          <a href="http://www.vardhamandistributors.in">
            www.vardhamandistributors.in
          </a>{" "}
          <br />
          <p>
            App directly in the computer of the pharmacy to place app orders
            with stock and ledger updates. Company Sales representative Mobile
            App to check stock &amp; sales statement round the clock.
          </p>
        </p>
        <br />
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{ marginBottom: "0in" }}
        >
          User-friendly atmosphere. One to One correspondence to Company Reps
          and Customers. Daily Delightful Dealing on whatsapp communication.
          Always welcoming.
        </p>
        <br />
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{ marginBottom: "0in" }}
        >
          An approach for Ethical and Company regulations maintenance. Mission
          to sustain healthy competition. Goal to Hold on Market Sales. Aim for
          Captivity in Oncotrust &amp; LifeSavers Trust.
        </p>
        <br />
        <p className="MsoListParagraphCxSpLast" style={{ marginBottom: "0in" }}>
          No export or outside trading without standing instructions of Company.
          To achieve trust and balance with all means. To confirm secret &amp;
          security guarded in all respects wherever Company / Customer is
          Concerned.
        </p>
        <p className="MsoNormal" style={{ marginBottom: "0in" }}>
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          align="center"
          style={{ marginBottom: "0in", textAlign: "center" }}
        >
          <b>
            <u>
              <span style={{ fontSize: "14.0pt", lineHeight: "115%" }}>
                Vardhaman Distributors Pharma Portfolio
              </span>
            </u>
          </b>
        </p>
        <br />
        <p
          className="MsoListParagraphCxSpFirst"
          style={{ marginBottom: "0in", textIndent: "-.25in" }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          The Firm is an Authorized distributor for over 100 pharmaceutical
          companies and its highlights are:
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{ marginBottom: "0in", textIndent: "-.25in" }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Major dealers for lifesaving drugs like,
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "0in",
            marginLeft: "1.0in",
            textIndent: "-.25in",
          }}
        >
          <li>Anticancer</li>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "0in",
            marginLeft: "1.0in",
            textIndent: "-.25in",
          }}
        >
          <li>CARDIAC</li>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "0in",
            marginLeft: "1.0in",
            textIndent: "-.25in",
          }}
        >
          <li>Nephro</li>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "0in",
            marginLeft: "1.0in",
            textIndent: "-.25in",
          }}
        >
          <li> Neuro</li>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "0in",
            marginLeft: "1.0in",
            textIndent: "-.25in",
          }}
        >
          <li>Nutraceuticals</li>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "0in",
            marginLeft: "1.0in",
            textIndent: "-.25in",
          }}
        >
          <li>Immunoglobulins</li>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "0in",
            marginLeft: "1.0in",
            textIndent: "-.25in",
          }}
        >
          <li>Protein Preparations</li>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "0in",
            marginLeft: "1.0in",
            textIndent: "-.25in",
          }}
        >
          <li>Ortho</li>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "0in",
            marginLeft: "1.0in",
            textIndent: "-.25in",
          }}
        >
          <li>Optho</li>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "0in",
            marginLeft: "1.0in",
            textIndent: "-.25in",
          }}
        >
          <li>Derma</li>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "0in",
            marginLeft: "1.0in",
            textIndent: "-.25in",
          }}
        >
          and all kinds of Pharmaceuticals &amp; Generic sectors.
        </p>
        <br />
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{ marginBottom: "0in" }}
        >
          Currently networking in the western Maharashtra region with
          Faster-Free delivery systems.
        </p>
        <br />
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{ marginBottom: "0in" }}
        >
          Patient drug counseling available as a drug information center.
        </p>
        <br />
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{ marginBottom: "0in" }}
        >
          Catering to more than 500 doctors &amp; Associates with hospital
          institutions.
        </p>
        <br />
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{ marginBottom: "0in" }}
        >
          Future benefits of getting major abroad companies for Indian
          marketing.
        </p>
        <br />
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{ marginBottom: "0in" }}
        >
          Monitored rates for Sale companies - to analyze.
        </p>
        <br />
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{ marginBottom: "0in" }}
        >
          Lowest rate benefits for life-saving drugs and generic formulations.
        </p>
        <br />
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{ marginBottom: "0in" }}
        >
          Can stake in More than a dozen monopoly companies for channeled
          marketing businesses routing.
        </p>
        <br />
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{ marginBottom: "0in" }}
        >
          Auto increment turnovers directly proportional to huge market sales
          arising in Pharma industry with apps.
        </p>
        <br />
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{ marginBottom: "0in" }}
        >
          Special surveillance for doctors' appointments and services.
        </p>
        <br />
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{ marginBottom: "0in" }}
        >
          Google location central located and Karnataka border to achieve speedy
          supply.
        </p>
        <br />
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{ marginBottom: "0in" }}
        >
          Combination of drugs and contents know-how with major software support
          and experienced staff of a 50-year-old firm.
        </p>
        <br />
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{ marginBottom: "0in" }}
        >
          Mass scale therapy - yet to come for announcements.
        </p>
        <br />
        <p className="MsoListParagraphCxSpLast" style={{ marginBottom: "0in" }}>
          Patients' benefits key factors: transparency track records and helping
          PAS patients assistance programs of your esteemed company cancer
          regimes.
        </p>
        <br />
        <p className="MsoNormal" style={{ marginBottom: "0in" }}>
          &nbsp;
        </p>
        <p className="MsoNormal" style={{ marginBottom: "0in" }}>
          <b>
            <u>VISION</u>
          </b>
        </p>
        <p className="MsoNormal" style={{ marginBottom: "0in" }}>
          Pan India Coverage development under process for godown expansions,
          courier reach, wide range digital approach from customized computer
          apps
        </p>
        <p className="MsoNormal" style={{ marginBottom: "0in" }}>
          &nbsp;
        </p>
        <p className="MsoNormal" style={{ marginBottom: "0in" }}>
          <b>Sir/Madam,</b>
        </p>
        <p className="MsoNormal" style={{ marginBottom: "0in" }}>
          <b>We will be Happy to serve you for the Best of Affairs.</b>
        </p>
        <p className="MsoNormal" style={{ marginBottom: "0in" }}>
          <b>With Regards, </b>
        </p>
        <p className="MsoNormal" style={{ marginBottom: "0in" }}>
          <b>Lalit Shah </b>
        </p>
        <p className="MsoNormal" style={{ marginBottom: "0in" }}>
          <b>Vardhaman Distributors, Miraj 416410. Maharashtra</b>
        </p>
        <p className="MsoNormal" style={{ marginBottom: "0in" }}>
          <b>&nbsp;</b>
        </p>
        <p className="MsoNormal" style={{ marginBottom: "0in" }}>
          <b>0233 2222119</b>
        </p>
        <p className="MsoNormal" style={{ marginBottom: "0in" }}>
          <b>0233 2222019</b>
        </p>
        <p className="MsoNormal" style={{ marginBottom: "0in" }}>
          <b>0233 2229406</b>
        </p>
        <p className="MsoNormal" style={{ marginBottom: "0in" }}>
          <b>Landline numbers </b>
        </p>
        <p className="MsoNormal" style={{ marginBottom: "0in" }}></p>
      </div>
    </div>
  );
};

export default AboutUs;
