import img2 from "./Images/logo.png";
import R_Logo from "./Images/R_Logo.png";
import { Link } from "react-router-dom";
import "./App.css";

export default function Header() {
  return (
    <>
      <div className="product_header">
        <Link to="/">
          <img
            src={img2}
            height={100}
            width={"100%"}
            id="S"
            alt=""
            style={{ marginTop: "5px", width: "100%" }}
          />
        </Link>
        <img src={R_Logo} height={100} width={100} className="R" alt="" />
      </div>
      <div className="lists">
        <Link
          to="/ProductList"
          style={{ textDecoration: "none", marginLeft: "30px" }}
        >
          <li>Company List</li>
        </Link>
        <Link to="/New_Product" style={{ textDecoration: "none" }}>
          <li>New Products</li>
        </Link>
        {/* <Link to="/All_P" style={{ textDecoration: "none" }}><li>Products List</li></Link> */}
      </div>
    </>
  );
}
