import { useRef, useState } from "react";
import { useEffect } from "react";
import CustomerFooter from "./CustomerFooter";
import HeaderCustomer from "./HeaderCustomer";
import { addDays } from 'date-fns';
import * as XLSX from 'xlsx';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { Table } from "react-bootstrap";
import './Customer.css';
import ApiHandler from "../../ApiHandler";
import TransferData from "../../TransferData";
import { Button, DatePicker, Input, Space } from "antd";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import LoaderTri from "../Loader/LoaderTri";



const { Search } = Input;

const initialValues = {
    from: dayjs(),
    to: dayjs()
};
export default function BounceList() {
    const [dates, setDates] = useState(initialValues);

    const [showBounceList, setShowBounceList] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [alldatesData, setAlldatesData] = useState([])
    const [openDate, setOpenDate] = useState(false)
    const [viewdata, setViewdata] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [range, setRange] = useState([{
        startdate: new Date(),
        endDate: addDays(new Date(), ''),
        key: 'selection'
    }])


    const refOne = useRef(null);

    useEffect(() => {
        document.addEventListener("keydown", hideOnEscape, true)
        document.addEventListener("click", hideOnClickOutside, true)
        return () => {
            document.removeEventListener("keydown", hideOnEscape, true)
            document.removeEventListener("click", hideOnClickOutside, true)
        }
    }, []);

    const hideOnEscape = (e) => {
        console.log(e.key)
        if (e.key === "Escape") {
            setOpenDate(false)
        }
    }


    const hideOnClickOutside = (e) => {
        if (refOne.current && !refOne.current.contains(e.target)) {
            setOpenDate(false)
        }
    }

    const handleSelects = (date) => {

        let filtered = alldatesData.filter((product) => {

            let productDate = new Date(product["date"]);
            return (
                productDate >= date.selection.startDate &&
                productDate <= date.selection.endDate

            );
        });
        setStartDate(date.selection.startDate);
        setEndDate(date.selection.endDate);
        setShowBounceList(filtered);
        console.log(date.selection.startDate)
        console.log(date.selection.endDate)
    };

    const selectionRange = {
        startDate: startDate,
        endDate: endDate,
        key: "selection",
    };


    function togetData() {
        setIsLoading(true)
        setViewdata(true)
        setShowBounceList([])
        const user = JSON.parse(localStorage.getItem('user-info'))
        const localUrl = JSON.parse(localStorage.getItem('LocalURL'))
        ApiHandler.post({
            url: localUrl + "api/OwnApp/GetShortItemList",
            data: {
                // startdate: moment(selectionRange.startDate).format("YYYYMMDD"),
                // enddate: moment(selectionRange.endDate).format("YYYYMMDD"),
                startdate: dayjs(dates.from).format("YYYYMMDD"),
                enddate: dayjs(dates.to).format("YYYYMMDD"),
                code: user.code,

            },
            useUserUrl: true
        })
            .then(((result) => {
                console.log(result);
                console.log(result.data);
                setAlldatesData(result.data);
                setShowBounceList(result.data);
            }))
            .catch(err => {
                console.error(err);
                if (err.response && err.response.status && err.response.status === 401) {
                    console.log(err.response);
                    //  RefreshTokenHandler();
                    //  ToastError("Token has expired Please refresh");
                }
            })
            .finally(() => {
                setIsLoading(false)
            })

    }



    function handleExportDataExcel() {
        console.log(showBounceList);
        var wb = XLSX.utils.book_new(),
            ws = XLSX.utils.json_to_sheet(showBounceList);
        XLSX.utils.book_append_sheet(wb, ws, "MySheet1");
        XLSX.writeFile(wb, "Reports.xlsx");
    }

    const [searchText, setSearchText] = useState("")
    const filterBySearch = (e) => {
        const keyword = e.target.value;

        if (keyword !== ' ') {
            setSearchText(keyword);
            // const results = showBounceList.filter((users) => users.product.toLowerCase().startsWith(keyword.toLowerCase())

            // );
            // setShowBounceList(results);
        } else {
            setShowBounceList(showBounceList);
            console.log(showBounceList)
        }


    };




    return (
        <>
            <HeaderCustomer />
            <div style={{ minHeight: window.innerHeight / 1.4 }}>
                <div className="row m-0">
                    <div className="col-lg-10 offset-lg-1">
                        <h5 style={{
                            marginTop: "20px",
                            // marginLeft: "20px",
                            fontFamily: "sans-serif",
                            // fontSize: "17px",
                            // padding: "10px"

                        }}>Select details to view Bounce List</h5>

                        <div className="row">
                            <div className="col-lg-3  ">
                                <DatePicker.RangePicker
                                    size="middle"
                                    format="DD-MM-YYYY"

                                    className=" mb-0 mr-2"
                                    value={[dates.from, dates.to]}
                                    onChange={(e) => {
                                        console.log(e);

                                        if (e) {
                                            setDates({
                                                from: e[0],
                                                to: e[1],
                                            });
                                        } else {
                                            setDates({ from: null, to: null });
                                        }
                                    }}
                                />
                            </div>
                            <div className="col-lg-2  ">
                                <Button
                                    type="button"
                                    className="btn   btn-primary btn-sm"
                                    onClick={togetData}
                                >View</Button>

                            </div>
                        </div>






                        {/* <div className="enterDateInput">

                            <form >
                                <input
                                    value={`${moment(selectionRange.startDate).format("DD-MM-YYYY")} to ${moment(selectionRange.endDate).format("DD-MM-YYYY")}`}
                                    // value={`${format(range[0].startdate, 'MM-dd-yyyy')}  to  ${format(range[0].endDate, 'MM-dd-yyyy')} `}

                                    readOnly
                                    onClick={() => setOpenDate(true)}
                                    style={{
                                        width: "300px",
                                        paddingLeft: '30px'

                                    }}
                                    required
                                />


                                <button
                                    //disabled={value === 0}
                                    type="button"
                                    onClick={togetData}

                                >View</button>
                            </form>
                        </div>
                        <div className="dateRangePicker" ref={refOne}>



                            {

                                openDate &&
                                <DateRange
                                    onChange={handleSelects}
                                    editableDateInputs={true}
                                    moveRangeOnFirstSelection={false}
                                    ranges={[selectionRange]}
                                    months={2}
                                    direction="horizontal"
                                    showMonthAndYearPickers={false}
                                    className="DaterangePicker"

                                />


                            }
                        </div> */}

                        {viewdata &&
                            <div className="row mt-3">
                                <div className="col-lg-6 col-6">
                                    <h3 className="bounceList_1H3">Bounce List </h3>
                                </div>
                                <div className="col-lg-6 col-6 text-end">
                                    {showBounceList.length > 0 && <button className="bounceList_1Button"
                                        onClick={handleExportDataExcel}>Download</button>}
                                </div>

                                <div className="col-lg-6 col-6">
                                    <h5 className="bounceList_1H5">show entries </h5>
                                </div>
                                <div className="col-lg-6 col-6  text-end  ">

                                    <Space.Compact className="mt-2 mb-2" size="middle">
                                        {/* <Search addonBefore="Search :" placeholder="input search text"
                                            onChange={filterBySearch}
                                            allowClear /> */}
                                        <Input addonBefore={<FontAwesomeIcon icon={faSearch} />}
                                            onChange={filterBySearch}
                                            placeholder="Search Product" />
                                    </Space.Compact>

                                    {/* <label className="">Search :</label>
                                    <input type="text" className="form-control form-control-sm w-25" /> */}
                                </div>

                                <div className="bounceListData col-lg-12">
                                    <div style={{
                                        width: "100%",
                                        overflowX: "scroll"
                                    }}>
                                        <Table striped >
                                            <thead className="threAd">
                                                <tr className="threAd_tr">
                                                    <th>{TransferData.orderDate}</th>
                                                    <th>{TransferData.product}</th>
                                                    <th>{TransferData.pack}</th>
                                                    <th style={{ textAlign: "right" }}>{TransferData.qty}</th>

                                                </tr>
                                            </thead>

                                            {


                                                showBounceList
                                                    .filter((users) =>
                                                        users.product.toLowerCase().startsWith(searchText.toLowerCase()))
                                                    .map((elm, index) => {
                                                        return <tbody className="tboDY" key={index} >
                                                            <tr >
                                                                <td >{elm.date}</td>
                                                                <td>{elm.product}</td>
                                                                <td >{elm.pack}</td>
                                                                <td style={{ textAlign: "right" }}>{elm.qty}</td>

                                                            </tr>


                                                        </tbody>



                                                    })

                                            }

                                        </Table >
                                    </div>
                                    {isLoading &&
                                        <div className="d-flex justify-content-center" >

                                            <LoaderTri />

                                        </div>

                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <CustomerFooter />
        </>
    )
}